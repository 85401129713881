import { ButtonBase, IconButton, SvgIcon, Theme, Typography, makeStyles } from '@material-ui/core';
import { mdiCamera, mdiClose, mdiPencil } from '@mdi/js';
import clsx from 'clsx';
import React, { ReactElement, useState } from 'react';

import { TeamStoreProductListFragment } from '../../../../../../../../generated/graphql';
import { SYSTEM_COLORS } from '../../../../../../../config/colors';
import { formatCurrencyValueFromServer } from '../../../../../../../utilites/currenty';
import P4 from '../../../../../../typography/p4/p4';
import P5 from '../../../../../../typography/p5';
import P6 from '../../../../../../typography/p6';
import AddImageDialog from '../addImageDialog';
import DropImage from '../dropImage';

interface Props {
	setRemoveProduct(productId: string): void;
	handleImageUpdate(id: string, url?: string): void;
	product: TeamStoreProductListFragment;
	dragHandle: ReactElement;
	editProduct(editProduct?: string): void;
}

const ProductItem = (props: Props): ReactElement => {
	const classes = useStyles();
	const [dialogState, setDialogState] = useState(false);

	const clickedButton = async (): Promise<void> => {
		setDialogState(!dialogState);
	};

	return (
		<>
			<div className={classes.container}>
				{props.dragHandle}
				<div className={classes.imageContainer}>
					{!props.product.display_photo && (
						<DropImage
							id={props.product.id}
							saveUrlOnSuccess={(url: string) => {
								props.handleImageUpdate(props.product.id, url);
							}}
						/>
					)}
					{props.product.display_photo && (
						<>
							<ButtonBase onClick={clickedButton} className={classes.addIconButton}>
								{!props.product.display_photo && (
									<SvgIcon className={classes.addIcon}>
										<path d={mdiCamera} />
									</SvgIcon>
								)}
								{props.product.display_photo && (
									<img src={props.product.display_photo} width={100} height={100} />
								)}
							</ButtonBase>
							<ButtonBase
								className={classes.clearImageButton}
								onClick={(): void => {
									props.handleImageUpdate(props.product.id, undefined);
								}}
							>
								<P6>Clear Image</P6>
							</ButtonBase>
						</>
					)}
				</div>
				<div className={classes.contentContainer}>
					<div className={classes.flexMajority}>
						<Typography className={classes.label} variant="body1">
							{props.product.label}
						</Typography>
						<P5
							className={clsx(
								classes.p5,
								props.product.custom_price_override && props.product.custom_price_override !== 0
									? classes.customPriceApplied
									: null
							)}
						>
							Base:{' '}
							{props.product.product.base_price
								? formatCurrencyValueFromServer(props.product.product.base_price)
								: 'NO BASE PRICE DEFINED'}
						</P5>
						{props.product.custom_price_override !== null &&
							props.product.custom_price_override !== undefined &&
							props.product.custom_price_override > 0 && (
								<P5 className={clsx(classes.p5, classes.customPrice)}>
									Custom Price:{' '}
									{props.product.custom_price_override
										? formatCurrencyValueFromServer(props.product.custom_price_override)
										: 'NO BASE PRICE DEFINED'}
								</P5>
							)}
						{props.product.enforce_limit_qty && (
							<P5 className={clsx(classes.p5, classes.customPrice)}>
								Limited Qty Left: {props.product.limit_order_qty - props.product.qty_purchased_calc}
							</P5>
						)}
						{props.product.meta_key && (
							<P5 className={clsx(classes.p5, classes.metaKey)}>KEY: {props.product.meta_key}</P5>
						)}
					</div>
					<div className={classes.optionContainer}>
						{/* {props.product.store_product_has_available_options.map((option) => {
							return (
								<div key={option.available_option.id} className={classes.option}>
									{option.available_option.name}
								</div>
							);
						})} */}
					</div>
				</div>
				<div className={classes.actionContainer}>
					<IconButton onClick={(): void => props.editProduct(props.product.id)}>
						<SvgIcon>
							<path d={mdiPencil} />
						</SvgIcon>
					</IconButton>
					<IconButton onClick={(): void => props.setRemoveProduct(props.product.id)}>
						<SvgIcon>
							<path d={mdiClose} />
						</SvgIcon>
					</IconButton>
				</div>
			</div>
			<AddImageDialog
				id={props.product.id}
				saveUrlOnSuccess={(url: string) => {
					props.handleImageUpdate(props.product.id, url);
					setDialogState(false);
				}}
				open={dialogState}
				handleClose={(): void => {
					setDialogState(false);
				}}
			/>
		</>
	);
};

const useStyles = makeStyles((theme: Theme) => {
	return {
		container: {
			display: 'flex',
			alignItems: 'center',
			marginBottom: 10,
			borderRadius: 8,
			padding: 16,
			border: `1px solid ${SYSTEM_COLORS.DIVIDER}`
		},

		imageContainer: {
			marginLeft: 6,
			marginRight: 20
		},
		label: {
			fontWeight: 600
		},
		p5: {
			color: SYSTEM_COLORS.GRAY_LIGHT
		},
		contentContainer: {
			height: 100,
			flex: 1,
			display: 'flex',
			flexDirection: 'column'
		},
		flexMajority: {
			flex: 1
		},
		optionContainer: {
			display: 'flex'
		},
		option: {
			fontSize: 12,
			fontWeight: 'bold',
			textTransform: 'uppercase',
			padding: '5px 20px',
			color: SYSTEM_COLORS.GRAY,
			borderRadius: 8,
			backgroundColor: SYSTEM_COLORS.GRAYSCALE_GRAY_LIGHTEST,
			marginRight: 10
		},
		actionContainer: {
			display: 'flex',
			alignItems: 'center',
			'& > :not(:first-child)': {
				marginLeft: 8
			}
		},
		addIconButton: {
			width: 100,
			height: 100
		},
		addIcon: {
			width: 50,
			height: 50
		},
		customPriceApplied: {
			textDecoration: 'line-through'
		},
		customPrice: {
			color: SYSTEM_COLORS.PRIMARY,
			fontWeight: 600
		},
		clearImageButton: {
			display: 'block',
			textAlign: 'center',
			width: '100%'
		},
		metaKey: {
			color: SYSTEM_COLORS.GRAY_LIGHT,
			padding: '5px 0px'
		}
	};
});

export default ProductItem;
