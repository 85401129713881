import { gql, useApolloClient } from '@apollo/client';
import { Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, TextField, Theme, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { ReactElement, useState } from 'react';

import { TeamStoreProductListFragment } from '../../../../../../../generated/graphql';
import PrimaryButton from '../../../../../buttons/primaryButton';

interface Props {
	open: boolean;
	storeId: string;
	handleClose: () => void;
	products: TeamStoreProductListFragment[];
}

interface QuantityUpdate {
	metaKey: string;
	quantity: number;
}

const UpdateQuantityDialog = (props: Props): ReactElement => {
	const [jsonInput, setJsonInput] = useState('');
	const [error, setError] = useState<string | null>(null);
	const [adjustForPurchased, setAdjustForPurchased] = useState(true);
	const classes = useStyles();
	const client = useApolloClient();
	const { enqueueSnackbar } = useSnackbar();

	// Clear error and input when dialog closes
	const handleClose = () => {
		setError(null);
		setJsonInput('');
		setAdjustForPurchased(true); // Reset to default
		props.handleClose();
	};

	// Clear error when input changes
	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setError(null);
		setJsonInput(e.target.value);
	};

	const validateAndParseJSON = (input: string): QuantityUpdate[] | null => {
		try {
			const parsed = JSON.parse(input);
			if (!Array.isArray(parsed)) {
				setError('Input must be an array of objects');
				return null;
			}

			const isValid = parsed.every(
				(item) => typeof item === 'object' && 'metaKey' in item && 'quantity' in item && typeof item.quantity === 'number'
			);

			if (!isValid) {
				setError('Each item must have metaKey (string) and quantity (positive number)');
				return null;
			}

			setError(null);
			return parsed as QuantityUpdate[];
		} catch (e) {
			setError('Invalid JSON format');
			return null;
		}
	};

	const handleSubmit = async () => {
		const updates = validateAndParseJSON(jsonInput);
		if (!updates) return;

		try {
			let str = '';
			updates.forEach((update, i) => {
				const product = props.products.find((p) => p.meta_key === update.metaKey);
				const currentPurchased = product?.qty_purchased_calc || 0;
				const newQuantity = adjustForPurchased ? update.quantity + currentPurchased : update.quantity;

				str += `
update${i}: update_store_product(
	where: {
		team_store_id: {_eq: "${props.storeId}"}, 
		meta_key: {_eq: "${update.metaKey}"},
		enabled: {_eq: true}
	}, 
	_set: {
		limit_order_qty: ${newQuantity}
	}
) {
	returning {
		id
		limit_order_qty
		qty_purchased_calc
	}
}`;
			});

			await client.mutate({
				mutation: gql`
					mutation UpdateMultipleQuantities {
						${str}
					}
				`
			});

			enqueueSnackbar('Successfully updated quantities!', {
				variant: 'success'
			});
			handleClose();
		} catch (error) {
			setError('Failed to update products');
		}
	};

	return (
		<Dialog open={props.open} onClose={handleClose} maxWidth="md" fullWidth>
			<DialogTitle>Update Product Quantities</DialogTitle>
			<DialogContent>
				<div className={classes.content}>
					<TextField
						fullWidth
						multiline
						rows={8}
						variant="outlined"
						label="JSON Input"
						value={jsonInput}
						onChange={handleInputChange}
						error={!!error}
						helperText={
							error ||
							'Enter JSON array of updates. Example:\n[{"metaKey": "meta_boone13", "quantity": 10}, {"metaKey": "meta_boone14", "quantity": 5}]'
						}
						className={classes.textField}
					/>
					<FormControlLabel
						control={
							<Checkbox
								checked={adjustForPurchased}
								onChange={(e) => setAdjustForPurchased(e.target.checked)}
								color="primary"
							/>
						}
						label="Stack this quantity with the quantity already purchased"
						className={classes.checkbox}
					/>
					<div className={classes.actions}>
						<PrimaryButton onClick={handleClose} variant="outlined">
							Cancel
						</PrimaryButton>
						<PrimaryButton onClick={handleSubmit} disabled={!jsonInput || !!error}>
							Update Quantities
						</PrimaryButton>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
};

const useStyles = makeStyles((theme: Theme) => ({
	content: {
		display: 'flex',
		flexDirection: 'column',
		gap: theme.spacing(2)
	},
	textField: {
		'& .MuiFormHelperText-root': {
			whiteSpace: 'pre-line'
		}
	},
	checkbox: {
		marginTop: theme.spacing(-1)
	},
	actions: {
		display: 'flex',
		justifyContent: 'flex-end',
		gap: theme.spacing(1)
	}
}));

export default UpdateQuantityDialog;
